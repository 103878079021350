<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="140px"
    class="detail-form"
  >
    <el-form-item label="应用名称" prop="appName">
      <el-input
        size="small"
        :maxlength="64"
        show-word-limit
        v-model="formData.appName"
        placeholder="请输入应用名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="应用分类" prop="appType">
      <el-select
        size="small"
        filterable
        v-model="formData.appType"
        placeholder="请选择应用分类"
      >
        <el-option
          v-for="item in appTypeList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="是否为运营商应用" prop="operatorApp">
      <el-radio-group v-model="formData.operatorApp">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <el-form-item label="默认语言" prop="language">
      <el-select
        size="small"
        filterable
        v-model="formData.language"
        placeholder="请选择默认语言"
      >
        <el-option
          v-for="item in languageList"
          :key="item.languageId"
          :label="item.languageName"
          :value="item.languageId"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="上架类型" prop="releaseType">
      <el-radio-group v-model="formData.releaseType">
        <el-radio :label="1">普通</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" @click="handleSubmit()"
        >确认</el-button
      >
      <el-button size="small" @click="handleCancel()">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { appStatusList, appTypeList } from "@/common/constant/constant.js";
export default {
  props: {
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      appTypeList,
      appStatusList,
      formData: {
        appName: "",
        appType: 2,
        language: "",
        releaseType: 1,
        operatorApp: 0,
      },
      rules: {
        releaseType: [{ required: true, message: "请输入", trigger: "blur" }],
        appName: [{ required: true, message: "请输入", trigger: "blur" }],
        appType: [{ required: true, message: "请选择" }],
        language: [{ required: true, message: "请选择" }],
        operatorApp: [{ required: true, message: "请选择" }],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formData);
        } else {
          this.$message.error("请检查输入的数据");
        }
      });
    },
    handleCancel() {
      this.$emit("cancle");
    },
    // 暴露给父组件调用刷新数据
    resetFields() {
      this.$refs.formRef.resetFields();
    },
  },
};
</script>
